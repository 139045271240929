import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faGrid2 } from '@fortawesome/pro-regular-svg-icons'
import { faTable } from '@fortawesome/pro-solid-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { ITransformedLeafItem } from '../../interface/transformed-leaf-item'
import { FlatViewBodyTemplateComponent } from './flat-view-body-template.component'
import {
  SortOrder, TFlatViewSortBy, TFlatViewTableColumn, Navigation, ProductLabel
} from '../../../shared/enum'
import { FlatViewHeaderComponent } from './flat-view-header-template.component'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { store } from '../../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { DecimalViewTogglerComponent } from '../../../shared/component/general/decimal-view-toggler.component'

import { PngFooterTemplateComponent } from './png-footer-template.component'
import { PngHeaderTemplateComponent } from './png-header-template.component'
import { DecimalViewType } from '../../../shared/enum/decimal'
import { MenuDownloadTableContainer } from '../../container/menu/menu-download-table.container'
import { MenuCompareComponent } from '../menu/menu-compare.component'
import { isValid } from '../../../shared/util/tools'
import { EmptyStateScreenLayout } from '../../../shared/layout/empty-state-screen.layout'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { InventoryViewType } from '../../enum/inventory-view-type'
import { TableWrapperInventoryComponent } from '../../../shared/component/general/table-wrapper-inventory.component'
import { Status } from '../../../shared/enum/status'
import { NotCompatibleImpactIcon } from '../../../shared/component/product/not-compatible-impact-icon'

const FlatViewWrapper = styled.div`
  &.flat-view-shadow {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }
`

export const InventoryFlatViewComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    productInventory: { product = null } = {},
    totalImpact: { amount: totalImpactAmount, status: totalImpactStatus, unit: totalImpactUnit = null } = {},
    transformedLeafItems = [], selectedDecimalViewType = DecimalViewType.NumericValue
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const navigate = useNavigate()
  const { productId } = useParams()
  const [ selectedSortBy, setSelectedSortBy ] = useState<TFlatViewSortBy>(TFlatViewSortBy.ImpactAmount)
  const [ selectedSortOrder, setSelectedSortOrder ] = useState<SortOrder>(SortOrder.Desc)
  const [ flatTableList, setFlatTableViewList ] = useState<ITransformedLeafItem[]>([])
  const [ selectedProductId, setSelectedProductId ] = useState<string | null>(null)
  const tableRef = useRef<HTMLDivElement>(null)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const [ productLabel ] = product?.labels || []
  const isObject = productLabel?.id === ProductLabel.Object

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  const selectedReduxProductId = SelectedProductSelector.productId(state)
  useEffect(() => {
    if (isDetailsPanelOpen) {
      setSelectedProductId(selectedReduxProductId)
    } else {
      setSelectedProductId(null)
    }
  }, [ isDetailsPanelOpen ])

  useEffect(() => {
    if (selectedSortBy && selectedSortOrder) {
      const sortedLeafList = getSortedList()
      setFlatTableViewList([ ...sortedLeafList ])
    }
  }, [ selectedSortBy, selectedSortOrder, transformedLeafItems ])

  const getSortedList = () => {
    const sortedList = transformedLeafItems.sort((a: any, b: any) => {
      let sortByA = a[selectedSortBy]
      let sortByB = b[selectedSortBy]
      if (selectedSortBy === TFlatViewSortBy.ImpactAmount
        || selectedSortBy === TFlatViewSortBy.ImpactRatioRelativeToTotal) {
        sortByA = parseFloat(sortByA)
        sortByB = parseFloat(sortByB)
      }
      if (sortByA < sortByB) {
        return -1
      }

      return sortByA > sortByB ? 1 : 0
    })

    if (selectedSortOrder === SortOrder.Desc) {
      return sortedList.reverse()
    }

    return sortedList
  }

  const handleSortTable = (sortBy: TFlatViewSortBy, sortOrderTo: SortOrder) => {
    setSelectedSortBy(sortBy)
    setSelectedSortOrder(sortOrderTo)
  }

  const onRowClick = (id: string) => {
    if (id === 'impact') return null

    if (id === selectedProductId) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    setSelectedProductId(id)
    store.dispatch(showProductInDetailsPanelSaga(id))
  }

  const isSelectedView = (selected: DecimalViewType) => selectedDecimalViewType === selected

  const getSelectedRow = () => flatTableList.find((item: ITransformedLeafItem) => item.id === selectedProductId)

  const headerTemplate = (label: string, sortBy?: TFlatViewSortBy, alignRight: boolean = true, sortDisabled = false) => (
    <FlatViewHeaderComponent
      label={label}
      sortBy={sortBy}
      selectedSortBy={selectedSortBy}
      selectedSortOrder={selectedSortOrder}
      alignRight={alignRight}
      sortDisabled={sortDisabled}
      onSortClick={handleSortTable}
    />
  )

  const bodyTemplate = (rowData: ITransformedLeafItem, column: TFlatViewTableColumn) => <FlatViewBodyTemplateComponent value={rowData} onRowClick={onRowClick} column={column} />

  const footerTemplate = () => (
    <div className="flex w-full justify-content-end">
      <div className="flex-none flex align-items-center justify-content-center text-primary-500 bg-primary-50 border-primary-100 border-1 border-round tag-icon">
        <FontAwesomeIcon icon={isObject ? faGrid2 : faTag} className="text-base footer-product-icon" />
      </div>
      { product && (
        <div onClick={() => product.id && onRowClick(product.id)} className="flex-grow-1 align-items-center text-sm font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer product-name">
          { t('labels.productAndUnit', { context: 'withTotal', unit: product.unit, name: product.name }) }
        </div>
      )}
      { (isSelectedView(DecimalViewType.NumericValue) || isSelectedView(DecimalViewType.ScientificValue))
      && (
        <div className="flex-none flex align-items-center font-medium text-sm pr-2">
          { totalImpactStatus === Status.NotCompatible && (
            <NotCompatibleImpactIcon hasChild addRightSpace />
          ) }
          { totalImpactAmount && (
            <>
              <DecimalPointComponent value={totalImpactAmount} enableTooltip decimalViewType={selectedDecimalViewType} />
              {' '}
              { totalImpactUnit }
            </>
          ) }
        </div>
      )}
      { isSelectedView(DecimalViewType.PercentageValue) && (
        <div className="flex-none flex align-items-center justify-content-end font-medium px-2 text-sm w-7rem text-right">
          100%
        </div>
      )}
      <div className="flex-none flex align-items-center p-2 w-9rem"></div>
    </div>
  )

  const productHasCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !isValid(product?.referenceProduct)
  const productHasNoItems = !product?.hasInventory
  const thereIsItemWithImpact = transformedLeafItems.some(item => item.impactAmount && Math.abs(Number(item.impactAmount)) > 0)

  if (productHasNoImpact && !thereIsItemWithImpact) {
    const goToModel = () => {
      addToBackToList(t('labels.tab', { context: 'table' }))
      account?.id && space?.slug && productId && navigate(generatePath(Navigation.ProductObjectInventoryTabs, {
        accountId: account.id,
        workspaceSlug: space.slug,
        productId,
        activeTab: InventoryViewType.Model
      }))
    }

    return (
      <EmptyStateScreenLayout
        wrapperClassName="h-30rem"
        title={t('labels.emptyState', { context: productHasNoItems ? 'addItemsToShowTable' : 'addImpactToShowTable', ns: 'common' })}
        icon={<FontAwesomeIcon icon={faTable} className="text-primary-100" fontSize={106} />}
        footer={<Button onClick={goToModel}>{t('actions.go_toModel', { ns: 'common' })}</Button>}
      />
    )
  }

  return (
    <FlatViewWrapper ref={tableRef} className="flex flex-column border-round-lg bg-white w-full p-6 flat-view-shadow flat-view-wrapper">
      <div data-html2canvas-ignore className="flex h-2rem text-2xl align-items-center mb-4 flat-view-header">
        <div className="flex-grow-1 flex align-items-center h-full">
          { t('labels.header', { context: 'chart', unit: totalImpactUnit, ns: 'common' }) }
        </div>
        <div className="flex-none flex gap-3">
          <MenuDownloadTableContainer tableRef={tableRef} />

          <MenuCompareComponent />

          <DecimalViewTogglerComponent />
        </div>
      </div>
      <PngHeaderTemplateComponent />
      <TableWrapperInventoryComponent>
        <DataTable value={flatTableList} responsiveLayout="scroll" selectionMode="single" selection={getSelectedRow()} footer={footerTemplate()}>
          <Column
            field={TFlatViewTableColumn.Id}
            body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Id)}
            header={headerTemplate('', TFlatViewSortBy.ID, false, true)}
            style={{ width: '2rem' }}
          />
          <Column
            field={TFlatViewTableColumn.Amount}
            body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Amount)}
            header={headerTemplate(t('labels.amount', { ns: 'common' }), TFlatViewSortBy.Amount, false)}
            style={{ width: '5.5rem' }}
          />
          <Column
            field={TFlatViewTableColumn.Unit}
            body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Unit)}
            header={headerTemplate(t('labels.unit', { ns: 'common' }), TFlatViewSortBy.Unit, false)}
            style={{ width: '2.25rem' }}
          />
          <Column
            field={TFlatViewTableColumn.Item}
            body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Item)}
            header={headerTemplate(t('labels.tableHeader', { context: 'nameOfItems', ns: 'common' }), TFlatViewSortBy.Name, false)}
          />
          { isSelectedView(DecimalViewType.NumericValue) && (
            <Column
              field={TFlatViewTableColumn.Impact}
              body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Impact)}
              header={headerTemplate(t('labels.impact', { ns: 'common' }), TFlatViewSortBy.ImpactAmount)}
            />
          )}
          { isSelectedView(DecimalViewType.ScientificValue) && (
            <Column
              field={TFlatViewTableColumn.Scientific}
              body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Scientific)}
              header={headerTemplate(t('labels.impact', { ns: 'common' }), TFlatViewSortBy.ImpactAmount)}
            />
          )}
          { isSelectedView(DecimalViewType.PercentageValue) && (
            <Column
              field={TFlatViewTableColumn.Percentage}
              body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.Percentage)}
              header={headerTemplate('%', TFlatViewSortBy.ImpactRatioRelativeToTotal)}
              style={{ width: '6.5rem' }}
            />
          )}
          <Column
            field={TFlatViewTableColumn.ImpactBar}
            body={(rowData: ITransformedLeafItem) => bodyTemplate(rowData, TFlatViewTableColumn.ImpactBar)}
            header={headerTemplate('', TFlatViewSortBy.ImpactRatioRelativeToMax, false, true)}
            style={{ width: '9.5rem' }}
          />
        </DataTable>
      </TableWrapperInventoryComponent>
      <PngFooterTemplateComponent />
    </FlatViewWrapper>
  )
}
