import { EntityType } from '../../../utils/const'
import {
  IInventoryItem, IInventoryItemNode, ILifecycle, IProduct
} from '../../model'
import { ITransformedLeafItem } from '../../product/interface/transformed-leaf-item'
import { ImpactType } from '../enum/impact'
import { isValid } from './tools'

type TTransformLeafItems = {
  leafInventoryItems?: IInventoryItem[]
  totalImpactAmount?: string
  maxImpactAmount?: string
}

type TTransformItemsToTreeProps = {
  count?: number
  rootInventoryItem?: IInventoryItemNode
  inventoryItems?: IInventoryItemNode[]
  inventoryTree?: TInventoryTreeItem[]
  expandedKeys?: string[]
}

export type TInventoryTreeItem = {
  key?: string,
  label?: string,
  expanded?: boolean,
  data?: any,
  children?: TInventoryTreeItem[]
}

export type TTransformMultiLeafItem = {
  id?: string,
  name?: string,
  primaryLeafItem?: ITransformedLeafItem,
  secondaryLeafItem?: ITransformedLeafItem,
  impactChangeEffect?: ImpactType | null,
  secondaryImpactChangeEffect?: ImpactType | null,
  commonItem?: boolean
}

const getAncestorNodes = (data: IInventoryItemNode, inventoryTree: TInventoryTreeItem[] = []) => {
  const rootNode = inventoryTree.find((item: TInventoryTreeItem) => (item.key === 'node:root'))
  const { parentNode = '' } = data

  if (parentNode === '') return [ rootNode ]

  const keyPath: string[] = []
  const nodeAncestors: TInventoryTreeItem[] = []
  const [ prefix, ...nodeKeys ] = parentNode.split(':')

  nodeKeys.forEach((nodeKey, index) => {
    const currentKeyPath = index === 0 ? `${prefix}:${nodeKey}` : `${keyPath[index - 1]}:${nodeKey}`
    const currentParentNode = index === 0 ? rootNode : nodeAncestors[index - 1]
    keyPath.push(currentKeyPath)

    const currentNode = currentParentNode?.children?.find(
      (node: TInventoryTreeItem) => (node.key === currentKeyPath)
    ) || null

    currentNode && nodeAncestors.push(currentNode)
  })

  return [ rootNode, ...nodeAncestors ]
}

const parseItemsToTree = ({
  inventoryItems = [], inventoryTree = [], expandedKeys = [], rootInventoryItem, count = 0
}: TTransformItemsToTreeProps) => {
  if (rootInventoryItem && inventoryTree.length === 0) {
    inventoryTree.push({
      key: 'node:root',
      label: rootInventoryItem.inventoryItem?.product?.name,
      expanded: true,
      data: {
        inventoryItem: { ...rootInventoryItem.inventoryItem }
      },
      children: []
    })
  }

  if (inventoryItems.length > 0 && count <= 1000) {
    const data = inventoryItems[0]
    const treeNode: TInventoryTreeItem = {
      key: data.nodeId,
      expanded: expandedKeys.some((key: string) => data.nodeId === key),
      data: {
        type: data?.inventoryItem?.product?.__typename === EntityType.FOLDER ? 'Folder' : 'File',
        ...data
      },
      children: [],
      label: data.inventoryItem?.product?.name
    }

    const ancestors = getAncestorNodes(data, inventoryTree) || []
    const parentNode = ancestors?.length > 0 ? ancestors[ancestors.length - 1] : null
    if (isValid(parentNode)) {
      const { product: { id = null } = {}, amount } = parentNode?.data?.inventoryItem || {}
      parentNode && parentNode.children?.push({
        ...treeNode,
        data: {
          ...treeNode.data,
          ancestors,
          parentItem: { id, amount }
        }
      })
    }

    inventoryItems.splice(0, 1)
    parseItemsToTree({
      inventoryItems, inventoryTree, expandedKeys, count: count + 1
    })
  }

  return { inventoryTree }
}

export const transformItemsToTree = ({
  inventoryItems = [], inventoryTree = [], expandedKeys = [], rootInventoryItem
}: TTransformItemsToTreeProps) => {
  parseItemsToTree({
    inventoryItems, inventoryTree, expandedKeys, rootInventoryItem
  })
  if (inventoryItems.length > 0) {
    transformItemsToTree({
      inventoryItems, inventoryTree, expandedKeys, rootInventoryItem
    })
  }

  return { inventoryTree }
}

const getImpactRatio = (value?: string, total?: string) => (value !== undefined && value !== null && total ? parseFloat(value) / parseFloat(total) : null)

export const transformLeafItems = ({ leafInventoryItems = [], totalImpactAmount }: TTransformLeafItems): ITransformedLeafItem[] => {
  const getAggregatedValues = (values: any[]) => values.filter((elt: any) => elt && typeof elt === 'number')
    .reduce((accumulator?: any, currentValue?: any) => accumulator + currentValue, 0)

  const transformedItems: ITransformedLeafItem[] = []
  leafInventoryItems.forEach((item: IInventoryItem) => {
    const { product: { id, name, unit } = {} } = item
    const itemExists = transformedItems.find((elt: ITransformedLeafItem) => elt?.id === id)
    if (!isValid(itemExists)) {
      const itemWithSameProduct = leafInventoryItems.filter((elt: IInventoryItem) => elt.product?.id === id)
      const impactsOfSameProduct = itemWithSameProduct.map((elt: IInventoryItem) => elt.impact?.amount && parseFloat(elt.impact?.amount))
      const amountsOfSameProduct = itemWithSameProduct.map((elt: IInventoryItem) => elt.amount && elt.amount)

      const aggregatedImpactAmount = getAggregatedValues(impactsOfSameProduct)
      const aggregatedAmountAmount = getAggregatedValues(amountsOfSameProduct)
      transformedItems.push({
        id,
        name,
        amount: aggregatedAmountAmount,
        unit,
        impactAmount: aggregatedImpactAmount?.toString(),
        impactRatioRelativeToTotal: getImpactRatio(aggregatedImpactAmount?.toString(), totalImpactAmount),
        impactStatus: item.impact?.status
      })
    }
  })

  const impacts = transformedItems.map((elt: ITransformedLeafItem) => elt?.impactAmount && parseFloat(elt.impactAmount))
    .filter((elt: any) => elt && typeof elt === 'number') as number[]
  const maxImpactAmount = Math.max(...impacts)

  return transformedItems.map((elt: ITransformedLeafItem) => ({
    ...elt,
    impactRatioRelativeToMax: getImpactRatio(elt.impactAmount?.toString(), maxImpactAmount?.toString())
  }))
}

export const transformEntitiesToOptions = (entities: IProduct[] | ILifecycle[] = []) => {
  const entityOptionsList = entities.map((entity:any) => ({
    label: entity.name || '',
    value: entity
  }))
  return entityOptionsList
}

export const transformToMultiLeafItems = (
  primaryLeafItems: ITransformedLeafItem[] = [],
  secondaryLeafItems: ITransformedLeafItem[] = []
): TTransformMultiLeafItem[] => {
  const primaryImpacts = primaryLeafItems.map((elt: ITransformedLeafItem) => elt?.impactAmount && parseFloat(elt.impactAmount))
    .filter((elt: any) => elt && typeof elt === 'number') as number[]
  const secondaryImpacts = secondaryLeafItems.map((elt: ITransformedLeafItem) => elt?.impactAmount && parseFloat(elt.impactAmount))
    .filter((elt: any) => elt && typeof elt === 'number') as number[]
  const maxImpactAmount = Math.max(...primaryImpacts, ...secondaryImpacts)

  const getMultiLeafItem = (leafItems: ITransformedLeafItem) => {
    const { id, name, ...rest } = leafItems
    return { id, name, rest }
  }

  const getImpactChangeEffect = (
    firstLeafItem?: ITransformedLeafItem,
    secondLeafItem?: ITransformedLeafItem
  ): ImpactType | null => {
    if (firstLeafItem?.impactAmount && secondLeafItem?.impactAmount
      && secondLeafItem?.impactAmount !== firstLeafItem?.impactAmount) {
      return parseFloat(firstLeafItem.impactAmount) > parseFloat(secondLeafItem.impactAmount)
        ? ImpactType.Positive
        : ImpactType.Negative
    } if (firstLeafItem?.impactAmount && parseFloat(firstLeafItem.impactAmount) !== 0 && !isValid(secondLeafItem?.impactAmount)) {
      return parseFloat(firstLeafItem.impactAmount) > 0 ? ImpactType.Positive : ImpactType.Negative
    } if (secondLeafItem?.impactAmount && parseFloat(secondLeafItem.impactAmount) !== 0 && !isValid(firstLeafItem?.impactAmount)) {
      return parseFloat(secondLeafItem.impactAmount) < 0 ? ImpactType.Positive : ImpactType.Negative
    }

    return null
  }

  const getLeafItemWithRatioRelativeToMax = (leafItem?: ITransformedLeafItem) => ({ ...leafItem, impactRatioRelativeToMax: getImpactRatio(leafItem?.impactAmount?.toString(), maxImpactAmount?.toString()) })

  const commonLeafItems = primaryLeafItems
    .filter(pItem => secondaryLeafItems.some(sItem => (sItem.id === pItem.id)))
    .map(item => {
      const { id, name, rest: primaryLeafItem } = getMultiLeafItem(item)
      const sItem = secondaryLeafItems.find(item => item.id === id) || {}
      const { rest: secondaryLeafItem } = getMultiLeafItem(sItem)
      return {
        id,
        name,
        commonItem: true,
        primaryLeafItem: getLeafItemWithRatioRelativeToMax(primaryLeafItem),
        secondaryLeafItem: getLeafItemWithRatioRelativeToMax(secondaryLeafItem),
        impactChangeEffect: getImpactChangeEffect(primaryLeafItem, secondaryLeafItem),
        secondaryImpactChangeEffect: getImpactChangeEffect(secondaryLeafItem, primaryLeafItem)
      }
    })
  const onlyPrimaryLeafItems = primaryLeafItems
    .filter(pItem => !secondaryLeafItems.some(sItem => (sItem.id === pItem.id)))
    .map(item => {
      const { id, name, rest: primaryLeafItem } = getMultiLeafItem(item)
      return {
        id,
        name,
        primaryLeafItem,
        secondaryLeafItem: {},
        commonItem: false,
        impactChangeEffect: getImpactChangeEffect(primaryLeafItem),
        secondaryImpactChangeEffect: getImpactChangeEffect({}, primaryLeafItem)
      }
    })
  const onlySecondaryLeafItems = secondaryLeafItems
    .filter(sItem => !primaryLeafItems.some(pItem => (pItem.id === sItem.id)))
    .map(item => {
      const { id, name, rest: secondaryLeafItem } = getMultiLeafItem(item)
      return {
        id,
        name,
        primaryLeafItem: {},
        secondaryLeafItem,
        commonItem: false,
        impactChangeEffect: getImpactChangeEffect({}, secondaryLeafItem),
        secondaryImpactChangeEffect: getImpactChangeEffect(secondaryLeafItem, {})
      }
    })

  return [
    ...commonLeafItems,
    ...onlyPrimaryLeafItems,
    ...onlySecondaryLeafItems
  ]
}
