import React from 'react'
import classNames from 'classnames'
import { Tooltip } from 'primereact/tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

type NotCompatibleImpactIconProps = {
  hasChild?: boolean,
  addRightSpace?: boolean,
  tooltipPosition?: 'left' | 'top' | 'right' | 'bottom',
}

export const NotCompatibleImpactIcon: React.FC<NotCompatibleImpactIconProps> = ({ hasChild, addRightSpace, tooltipPosition = 'left' }) => {
  const { t } = useTranslation([ 'product' ])
  const tooltipLabelKey = hasChild ? 'hasItemsWithImpactNotCompatible' : 'treeItemWithImpactNotCompatible'
  const randomId = Math.random().toString(36).substring(7)
  const id = `impact-icon-${randomId}`

  return (
    <>
      <Tooltip target={`.${id}`} position={tooltipPosition} showDelay={500}>
        <Trans t={t} ns="product" i18nKey={`labels.${tooltipLabelKey}`} />
      </Tooltip>
      <div
        className={classNames(`${id} flex justify-content-center align-items-center`, {
          'mr-2': addRightSpace,
        })}
      >
        <FontAwesomeIcon
          data-testid="tree-item-invalid-impact-icon"
          icon={faExclamationTriangle}
          className="text-xl text-orange-700"
        />
      </div>
    </>
  )
}
