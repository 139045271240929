import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { Trans, useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

import { GettingStatedChecklistItem } from '../../../shared/enum/sign-up-experience'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { ExternalSources, Navigation } from '../../../shared/enum'

type TGettingStartedPanelContentComponentProps = {
  step: number,
  gettingStartedChecklistItem: GettingStatedChecklistItem
}
export const GettingStartedPanelContentComponent = ({
  step,
  gettingStartedChecklistItem
}: TGettingStartedPanelContentComponentProps) => {
  const navigate = useNavigate()

  const { t } = useTranslation([ 'workspace' ])
  const {
    openTalkToExpert = () => {},
    isPaidPineappleUser = () => false,
    isTrialPineappleUser = () => false
  } = useContext<IDashboardContext>(DashboardContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const isChecklistItem = (item: GettingStatedChecklistItem) => (gettingStartedChecklistItem === item)

  const bookFreeCall = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_TRIAL)
    openTalkToExpert(ExternalSources.HubSpotCalendarForCarbonTrialUrl)
  }

  const bookIntroCall = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_FOOTPRINT_KICKOFF)
    openTalkToExpert(ExternalSources.HubSpotCalendarForFootprintKickoffUrl)
  }

  const bookFootprintCheckCall = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_FOOTPRINT_CHECK)
    openTalkToExpert(ExternalSources.HubSpotCalendarForFootprintCheckUrl)
  }

  const triggerCheckList = () => {
    navigate(Navigation.Root, { state: { isFromPineappleTrailStart: true } })
  }

  const getTranslationKey = () => {
    if (isTrialPineappleUser()) {
      return `labels.gettingStarted_${gettingStartedChecklistItem}_TrialContent`
    }

    if (isPaidPineappleUser()) {
      return `labels.gettingStarted_${gettingStartedChecklistItem}_PaidContent`
    }
  }

  return (
    <div className="flex flex-column gap-3 w-full py-3 px-6">
      <div className="flex w-full gap-5">
        <div className="flex justify-content-center w-12rem h-8rem">
          <img data-testid="getting-started-step-img" src={`/assets/images/getting-started-step-${step}.png`} className="w-12rem" alt="" />
        </div>
        <div className="flex-grow-1">
          <Trans t={t} ns="workspace" i18nKey={getTranslationKey()} />
        </div>
      </div>
      {isTrialPineappleUser() && (
        <div className="flex w-full justify-content-end h-2rem">
          { !isChecklistItem(GettingStatedChecklistItem.ModelYourProduct)
            && (
              <Button className="p-button-outlined p-button-plain text-sm h-2rem" onClick={bookFreeCall}>
                {t('actions.book', { context: 'freeCall' })}
              </Button>
            ) }
          { isChecklistItem(GettingStatedChecklistItem.ModelYourProduct)
            && (
              <Button className="p-button-primary text-sm h-2rem" onClick={triggerCheckList}>
                {t('actions.gettingStarted', { context: 'startModeling' })}
              </Button>
            ) }
        </div>
      )}
      {isPaidPineappleUser() && (
        <div className="flex w-full justify-content-end h-2rem">
          { isChecklistItem(GettingStatedChecklistItem.CreateProductBlueprint)
            && (
              <Button className="p-button-primary text-sm h-2rem" onClick={bookIntroCall}>
                {t('actions.book', { context: 'introCall' })}
              </Button>
            ) }
          { isChecklistItem(GettingStatedChecklistItem.GetFootprintCheck)
            && (
              <Button className="p-button-primary text-sm h-2rem" onClick={bookFootprintCheckCall}>
                {t('actions.book', { context: 'footprintCheck' })}
              </Button>
            ) }
        </div>
      )}
    </div>
  )
}
