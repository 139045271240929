import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Dashboard } from './dashboard/dashboard'
import { Navigation } from './shared/enum/navigation'

import ErrorBoundary from '../component/error/errorBoundary'
import { Changelog, HelpCenter, LoginCallback } from '../component/entry'
import AuthorizationFailedPage from '../component/pages/errors/authorizationFailedPage'
import PasswordResetPage from '../component/pages/password-reset-page/passwordResetPage'
import { SignUp } from './dashboard/component/sign-up.component'
import {
  // InventoryPageLocation,
  LifecycleItemLocation
} from '../component/shared/locations'
import LifecycleItem from '../component/model/lifecycle/lifecycle-item/lifecycleItem.container.js'
// import InventoryPage from '../component/pages/inventory-page/inventoryPage.container'
import AccountSettings from '../component/account/settings.container'
import UserProfile from '../component/user/userProfile.container'
import NoMatchPage from '../component/pages/errors/noMatchPage'
import { WorkspaceEntry } from './workspace/workspace.entry'
import { ProductOverviewEntry } from './product/product-overview.entry'
import { LifecycleOverviewEntry } from './lifecycle/lifecycle-overview.entry'
import { ObjectOverviewEntry } from './product/object-overview.entry'
import { ProductDetailEntry } from './product/product-detail.entry'
import { ProductAnalysisEntry } from './analysis/product-analysis.entry'
import { AnalysisMode } from './analysis/enum/analysis'
import { LifecycleDetailEntry } from './lifecycle/lifecycle-detail.entry'
import { Logout } from './dashboard/component/logout.component'
import { PasswordReset } from './dashboard/component/password-reset.component'
import { ImpactDatasetEntry } from './impact-dataset/impact-dataset.entry'
import { GettingStartedEntry } from './workspace/getting-started.entry'

export const AppRoute = () => {
  const navigate = useNavigate()
  return (
    <ErrorBoundary>
      <Routes>
        <Route path={Navigation.SignUp} element={<SignUp />} />
        <Route path={Navigation.LoginCallback} element={<LoginCallback />} />
        <Route path={Navigation.FailedAuthorization} element={<AuthorizationFailedPage />} />
        <Route path={Navigation.ResetPasswordEmail} element={<PasswordResetPage />} />
        <Route path={Navigation.Logout} element={<Logout />} />
        <Route path={Navigation.ResetPassword} element={<PasswordReset />} />
        <Route path={Navigation.HelpCenter} element={<HelpCenter />} />
        <Route path={Navigation.Changelog} element={<Changelog />} />
        <Route path={Navigation.Root} element={<Dashboard />}>
          { [
            Navigation.Root,
            Navigation.Account,
            Navigation.AccountDetail,
            Navigation.WorkspaceDetail
          ].map((path, index) => <Route path={path} element={<WorkspaceEntry />} key={index} />) }
          <Route path={Navigation.GettingStated} element={<GettingStartedEntry />} />
          <Route path={Navigation.ProductOverview} element={<ProductOverviewEntry />} />
          <Route path={Navigation.ObjectOverview} element={<ObjectOverviewEntry />} />
          <Route path={Navigation.LifeCycleOverview} element={<LifecycleOverviewEntry />} />
          <Route path={LifecycleItemLocation.path} element={<LifecycleItem />} />
          <Route path={Navigation.LifeCycleDetail} element={<LifecycleDetailEntry />} />
          {/* <Route path={ Navigation.ProductObjectScenario } element={<InventoryPage/>} /> */ }
          <Route path={Navigation.ProductObjectInventory} element={<ProductDetailEntry />} />
          <Route path={Navigation.ProductComparison} element={<ProductAnalysisEntry />} />
          <Route path={Navigation.ProductScenario} element={<ProductAnalysisEntry analysisMode={AnalysisMode.Scenario} />} />
          <Route path={Navigation.ImpactDataset} element={<ImpactDatasetEntry />} />
          <Route path={Navigation.AccountSetting} element={<AccountSettings navigate={navigate} />} />
          <Route path={Navigation.UserProfile} element={<UserProfile />} />
          <Route path="*" element={<NoMatchPage />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  )
}
