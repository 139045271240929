import React, { useContext } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDatabase, faCircleNodes, faPenField, faPage
} from '@fortawesome/pro-regular-svg-icons'

import { DecimalViewType, NumberPrecision, TFlatViewTableColumn } from '../../../shared/enum'
import { ITransformedLeafItem } from '../../interface/transformed-leaf-item'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { ImpactBarComponent } from '../impact-bar.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { isValid } from '../../../shared/util/tools'
import { normalize } from '../../../../utils/tools'
import { ImpactSource } from '../../../shared/enum/impact'
import { Status } from '../../../shared/enum/status'
import { NotCompatibleImpactIcon } from '../../../shared/component/product/not-compatible-impact-icon'

const ColumnWrapper = styled.div`
  line-height: 1rem;
  padding: 0.75rem 1.25rem;
`

type FlatViewBodyTemplateComponentProps = {
  onRowClick?: Function,
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: TFlatViewTableColumn,
  value: ITransformedLeafItem
}

export const FlatViewBodyTemplateComponent = ({
  onRowClick = () => {},
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = TFlatViewTableColumn.Item,
  value
}: FlatViewBodyTemplateComponentProps) => {
  const {
    totalImpact: { unit: totalImpactUnit = null } = {},
    selectedDecimalViewType
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { id, impactRatioRelativeToMax } = value
  const ratioStatus = impactRatioRelativeToMax && impactRatioRelativeToMax > 0 ? 'positiveBar' : 'negativeBar'
  const impactType = `${ratioStatus}Deep`
  const unsignedImpactRatio = impactRatioRelativeToMax ? Math.abs(impactRatioRelativeToMax) : 0
  const impactPercent = unsignedImpactRatio <= 100 ? `${unsignedImpactRatio * 100}%` : '100%'

  const getTooltipContent = () => (
    <div className="max-w-15rem min-w-5rem white-space-nowrap">
      <DecimalPointComponent value={value?.impactAmount} />
      {' '}
      { totalImpactUnit }
    </div>
  )
  const isPercentage = selectedDecimalViewType === DecimalViewType.PercentageValue

  const getColumnIcon = () => {
    const { impactSource } = value
    switch (impactSource) {
    case ImpactSource.Custom: return faPenField
    case ImpactSource.ElementaryFlow: return faCircleNodes
    case ImpactSource.Dataset: return faDatabase
    default: return faPage
    }
  }

  const isNotCompatible = value?.impactStatus === Status.NotCompatible

  const getBodyTemplate = () => ({
    [TFlatViewTableColumn.Id]: (
      <div data-testid="flat-view-body-template-id" className="flex align-items-center w-full border-round-left-lg pl-4 pr-0">
        <div className="flex-none flex align-items-center justify-content-center w-1rem h-1rem">
          <FontAwesomeIcon data-testid="flat-view-body-template-icon" icon={getColumnIcon()} className="text-base" />
        </div>
      </div>
    ),
    [TFlatViewTableColumn.Item]: (
      <ColumnWrapper data-testid="flat-view-body-template-item" data-cy={`row-${normalize(value?.name)}-name`} className="flex align-items-center w-full border-round-left-lg">
        <div className="flex flex-grow-1 align-items-center text-sm line-height-2">
          { value?.name }
        </div>
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.Amount]: (
      <ColumnWrapper data-testid="flat-view-body-template-amount" data-cy={`row-${normalize(value?.name)}-amount`} className="flex align-items-center justify-content-start w-full text-sm">
        <DecimalPointComponent value={value?.amount} enableTooltip decimalViewType={isPercentage ? DecimalViewType.NumericValue : selectedDecimalViewType} />
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.Unit]: (
      <ColumnWrapper data-testid="flat-view-body-template-unit" className="flex align-items-center justify-content-start w-full text-sm ">
        { value?.unit }
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.Scientific]: (
      <ColumnWrapper data-testid="flat-view-body-template-scientific-impact" data-cy={`row-${normalize(value?.name)}-impact`} className="flex align-items-center justify-content-end w-full text-sm white-space-nowrap">
        <DecimalPointComponent value={value?.impactAmount} enableTooltip decimalViewType={DecimalViewType.ScientificValue} />
        {' '}
        { totalImpactUnit }
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.Impact]: (
      <ColumnWrapper data-testid="flat-view-body-template-impact" data-cy={`row-${normalize(value?.name)}-impact`} className="flex align-items-center justify-content-end w-full text-sm white-space-nowrap">
        { isNotCompatible ? (
          <NotCompatibleImpactIcon />
        ) : (
          <>
            <DecimalPointComponent value={value?.impactAmount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
            {' '}
            { totalImpactUnit }
          </>
        )}
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.Percentage]: (
      <ColumnWrapper data-testid="flat-view-body-template-percentage" data-cy={`row-${normalize(value?.name)}-percentage`} className="flex align-items-center justify-content-end w-full text-sm ">
        <DecimalPointComponent value={value?.impactRatioRelativeToTotal} decimalPlaces={NumberPrecision.Low} enableTooltip decimalViewType={DecimalViewType.PercentageValue} />
      </ColumnWrapper>
    ),
    [TFlatViewTableColumn.ImpactBar]: (
      <ColumnWrapper data-testid="flat-view-body-template-impactBar" className="flex align-items-center justify-content-start w-full text-sm border-round-right-lg">
        { isValid(value?.impactRatioRelativeToMax) && <ImpactBarComponent nodeKey={value.id} impactPercent={impactPercent} impactType={impactType} getTooltipContent={getTooltipContent} />}
      </ColumnWrapper>
    )
  })

  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0 cursor-pointer"
      onMouseEnter={() => onRowMouseEnter(id)}
      onMouseLeave={() => onRowMouseLeave()}
      onClick={() => onRowClick(id)}
    >
      { getBodyTemplate()[column] }
    </div>
  )
}
