import React, { useContext } from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { faPage } from '@fortawesome/pro-regular-svg-icons'

import { EComparisonTableColumn } from '../../../enum/table-column'
import { TTransformMultiLeafItem } from '../../../../shared/util/transform'

import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { normalize } from '../../../../../utils/tools'
import { isValid } from '../../../../shared/util/tools'
import { ImpactBarComponent } from '../../../../product/component/impact-bar.component'
import { ImpactType } from '../../../../shared/enum/impact'
import { Status } from '../../../../shared/enum/status'
import { NotCompatibleImpactIcon } from '../../../../shared/component/product/not-compatible-impact-icon'

const ColumnWrapper = styled.div`
  line-height: 1.25rem;
  padding: 0.75rem 1.25rem;

  &.col-impact-change {
    padding: 0.75rem 0.5rem;
  }

  &.col-impact-bar {
    padding-left: 0.25rem;
  }
`

type TableBodyTemplateComponentProps = {
  onRowClick?: Function,
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: EComparisonTableColumn,
  value: TTransformMultiLeafItem,
  rowIndex?: number
}

export const TableBodyTemplateComponent = ({
  onRowClick = () => {},
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = EComparisonTableColumn.Item,
  value,
  rowIndex
}: TableBodyTemplateComponentProps) => {
  const {
    primaryTotalImpact: { unit: totalImpactUnit = null } = {},
    secondaryTotalImpact: { unit: secondaryTotalImpactUnit = null } = {}
  } = useContext<TAnalysisContext>(AnalysisContext)
  const {
    impactRatioRelativeToMax,
    impactAmount,
    amount,
    unit
  } = value.primaryLeafItem || {}
  const {
    impactRatioRelativeToMax: secondaryImpactRatioRelativeToMax,
    impactAmount: secondaryImpactAmount,
    amount: secondaryAmount,
    unit: secondaryUnit
  } = value.secondaryLeafItem || {}

  const getImpactDetail = (impactRatio?: number | null) => {
    if (!impactRatio) return {}
    const ratioStatus = impactRatio && impactRatio > 0 ? 'positiveBar' : 'negativeBar'
    const impactType = `${ratioStatus}Deep`
    const unsignedImpactRatio = impactRatio ? Math.abs(impactRatio) : 0
    const impactPercent = unsignedImpactRatio <= 100 ? `${unsignedImpactRatio * 100}%` : '100%'

    return { impactType, impactPercent }
  }

  const { impactType, impactPercent } = getImpactDetail(impactRatioRelativeToMax)
  const { impactType: secondaryImpactType, impactPercent: secondaryImpactPercent } = getImpactDetail(secondaryImpactRatioRelativeToMax)

  const getTooltipContent = () => (
    <div className="max-w-15rem min-w-5rem white-space-nowrap">
      <DecimalPointComponent value={impactAmount} /> { totalImpactUnit }
    </div>
  )

  const getSecondaryTooltipContent = () => (
    <div className="max-w-15rem min-w-5rem white-space-nowrap">
      <DecimalPointComponent value={secondaryImpactAmount} /> { secondaryTotalImpactUnit }
    </div>
  )

  const impactIsPositive = value?.secondaryImpactChangeEffect === ImpactType.Positive
  const primaryImpactIsNotCompatible = value?.primaryLeafItem?.impactStatus === Status.NotCompatible
  const secondaryImpactIsNotCompatible = value?.secondaryLeafItem?.impactStatus === Status.NotCompatible

  const getBodyTemplate = () => ({
    [EComparisonTableColumn.Id]: (
      <div data-testid="table-body-template-id" className="flex align-items-center w-full border-round-left-lg pl-4 pr-0">
        <div className="flex-none flex align-items-center justify-content-center w-1rem h-1rem">
          <FontAwesomeIcon data-testid="table-body-template-icon" icon={faPage} className="text-base" />
        </div>
      </div>
    ),
    [EComparisonTableColumn.Item]: (
      <ColumnWrapper data-testid="table-body-template-item" data-cy={`row-${normalize(value?.name)}-name`} className="flex align-items-center w-full border-round-left-lg">
        <div className="flex flex-grow-1 align-items-center text-sm line-height-2">
          { value?.name }
        </div>
      </ColumnWrapper>
    ),

    [EComparisonTableColumn.PrimaryAmount]: (
      <ColumnWrapper data-testid="table-body-template-primary-amount" data-cy={`row-${normalize(value?.name)}-amount`} className="flex align-items-center justify-content-start w-full text-sm">
        { amount && <DecimalPointComponent value={amount} enableTooltip /> }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.PrimaryUnit]: (
      <ColumnWrapper data-testid="table-body-template-primary-unit" className="flex align-items-center justify-content-start w-full text-sm ">
        { unit }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.PrimaryImpact]: (
      <ColumnWrapper data-testid="table-body-template-primary-impact" data-cy={`row-${normalize(value?.name)}-impact`} className="flex align-items-center justify-content-end w-full text-sm white-space-nowrap">
        { primaryImpactIsNotCompatible ? (
          <NotCompatibleImpactIcon />
        ) : impactAmount && <DecimalPointComponent value={impactAmount} enableTooltip /> }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.PrimaryImpactBar]: (
      <ColumnWrapper data-testid="table-body-template-primary-impactBar" className="flex align-items-center justify-content-start w-full text-sm border-round-right-lg">
        { isValid(impactRatioRelativeToMax) && impactPercent && <ImpactBarComponent nodeKey={`${EComparisonTableColumn.PrimaryImpactBar}-${rowIndex}`} impactPercent={impactPercent} impactType={impactType} getTooltipContent={getTooltipContent} />}
      </ColumnWrapper>
    ),

    [EComparisonTableColumn.SecondaryAmount]: (
      <ColumnWrapper data-testid="table-body-template-secondary-amount" data-cy={`row-${normalize(value?.name)}-amount`} className="flex align-items-center justify-content-start w-full text-sm">
        { secondaryAmount && <DecimalPointComponent value={secondaryAmount} enableTooltip /> }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.SecondaryUnit]: (
      <ColumnWrapper data-testid="table-body-template-secondary-unit" className="flex align-items-center justify-content-start w-full text-sm ">
        { secondaryUnit }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.SecondaryImpact]: (
      <ColumnWrapper data-testid="table-body-template-secondary-impact" data-cy={`row-${normalize(value?.name)}-impact`} className="flex align-items-center justify-content-end w-full text-sm white-space-nowrap">
        { secondaryImpactIsNotCompatible ? (
          <NotCompatibleImpactIcon />
        ) : secondaryImpactAmount && <DecimalPointComponent value={secondaryImpactAmount} enableTooltip /> }
      </ColumnWrapper>
    ),
    [EComparisonTableColumn.SecondaryImpactBar]: (
      <ColumnWrapper data-testid="table-body-template-secondary-impactBar" className="flex align-items-center justify-content-start w-full text-sm border-round-right-lg col-impact-bar">
        { isValid(secondaryImpactRatioRelativeToMax) && secondaryImpactPercent && <ImpactBarComponent nodeKey={`${EComparisonTableColumn.SecondaryImpactBar}-${rowIndex}`} impactPercent={secondaryImpactPercent} impactType={secondaryImpactType} getTooltipContent={getSecondaryTooltipContent} />}
      </ColumnWrapper>
    ),

    [EComparisonTableColumn.ImpactChangeEffect]: (
      <ColumnWrapper data-testid="table-body-template-change" className="flex align-items-center justify-content-end w-full text-sm col-impact-change">
        { value?.secondaryImpactChangeEffect && (
          <FontAwesomeIcon
            data-testid="tree-footer-impactIcon"
            icon={impactIsPositive ? faCircleArrowUp : faCircleArrowDown}
            className={classNames('flex-none align-items-center text-base', {
              'text-red-500': impactIsPositive,
              'text-green-500': !impactIsPositive
            })}
          />
        ) }
      </ColumnWrapper>
    ),
  })

  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0 cursor-pointer"
      onMouseEnter={() => onRowMouseEnter(value.id)}
      onMouseLeave={() => onRowMouseLeave()}
      onClick={() => onRowClick(value.id)}
    >
      { getBodyTemplate()[column] }
    </div>
  )
}
