import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { REFERENCE_DATABASE, REFERENCE_PRODUCT } from '../../graphql/query/reference'
import { ReferenceProductType } from '../enum/reference-product'
import { FuzzyType } from '../../../__generated__/graphql'
import { isValid } from '../../shared/util/tools'
import { PAGINATION } from '../../../utils/const'

type TImpactDatasetContainerProps = {
  type: ReferenceProductType
  impactMethodId: string
  children?: React.ReactNode
}
export const ImpactDatasetContainer = ({
  type,
  impactMethodId,
  children
}: TImpactDatasetContainerProps) => {
  const {
    query = '',
    locationQuery = '',
    databaseFilterItems = [],
    categoryFilterItems = [],
    currentPage = 1,
    referenceDatabases,
    updateImpactDataset
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ skipReferenceQuery ] = useState<boolean>(true)

  const databases = databaseFilterItems.map(databaseFilterItem => databaseFilterItem.id)
  let categoryId = ''
  if (categoryFilterItems.length > 1) {
    categoryId = categoryFilterItems[categoryFilterItems.length - 1].parentCategory?.id || ''
  }

  const variables = {
    categoryId,
    currentPage,
    databases,
    impactMethodId,
    fuzzyType: FuzzyType.Exact,
    locationQuery,
    pageSize: PAGINATION.PAGE_SIZE,
    query,
    types: [ type ]
  }
  const {
    loading: loadingReferenceProducts,
    data: referenceProductsData,
  } = useQuery(REFERENCE_PRODUCT, {
    skip: !skipReferenceQuery,
    variables,
    fetchPolicy: 'no-cache'
  })

  const { items: referenceProducts, total: totalItems } = referenceProductsData?.referenceProducts || {}
  useEffect(() => {
    if (!loadingReferenceProducts && referenceProductsData) {
      const hasActiveCategoryFilter = categoryFilterItems.length > 1 || !!categoryFilterItems[0]?.selectedCategory
      const sideOrTopFilterEnabled = hasActiveCategoryFilter || !!locationQuery || databaseFilterItems.length > 0
      const searchOrFilterEnabled = sideOrTopFilterEnabled || !!query

      updateImpactDataset({
        referenceProducts: searchOrFilterEnabled ? referenceProducts : [],
        totalItems: searchOrFilterEnabled ? totalItems : 0
      })
    }
  }, [ loadingReferenceProducts, referenceProductsData ])

  const {
    loading: loadingReferenceDatabases,
    data: referenceDatabasesData
  } = useQuery(REFERENCE_DATABASE, {
    skip: isValid(referenceDatabases),
    variables: {
      methodId: impactMethodId
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingReferenceDatabases && referenceDatabasesData) {
      const { referenceDatabases = [] } = referenceDatabasesData || {}
      updateImpactDataset({ referenceDatabases })
    }
  }, [ loadingReferenceDatabases, referenceDatabasesData ])

  return (
    <>
      { children }
    </>
  )
}
