import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct } from '../../model'
import { Navigation, ProductType } from '../../shared/enum'
import { getNavigationStorageItem, removeNavigationStorageItem, setNavigationStorageItem } from '../../shared/util/navigation'
import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { store } from '../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../redux/sagas/product.saga'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { isValid } from '../../shared/util/tools'

export const useDatasetNavigate = (selectedProduct?: IProduct) => {
  const { t } = useTranslation('common')
  const {
    accountId,
    workspaceSlug,
    inventoryItemId,
  } = useParams()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const { addToBackToList, updateDashboard } = useContext(DashboardContext)
  const { updateImpactDataset } = useContext(ImpactDatasetContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const activeTab = searchParams.get('activeTab')
  const viewMode = searchParams.get('viewMode')

  const getBackLinkLabel = () => {
    if (matchPath(Navigation.ProductOverview, pathname)) {
      return t('labels.product_other')
    }
    if (matchPath(Navigation.ObjectOverview, pathname)) {
      return t('labels.object')
    }
    if (matchPath(Navigation.ProductScenario, pathname) || matchPath(Navigation.LifeCycleScenario, pathname)) {
      return t('labels.scenario')
    }
    return t('labels.tab_model')
  }

  const navigateBackToSource = () => {
    const { pathname: backLinkFromStorage } = getNavigationStorageItem(inventoryItemId!) || {}

    if (backLinkFromStorage && inventoryItemId) {
      removeNavigationStorageItem(inventoryItemId)
      navigate(backLinkFromStorage)
    } else {
      const productPageLink = generatePath(Navigation.ProductObjectInventory, {
        accountId: accountId!,
        workspaceSlug: workspaceSlug!,
        productId: inventoryItemId!
      })
      navigate(productPageLink)
    }
    store.dispatch(showProductInDetailsPanelSaga(inventoryItemId))
  }

  const storeSelectedProduct = (selectedProduct: IProduct) => {
    setNavigationStorageItem(selectedProduct.id!, {
      pathname: pathname + search,
      data: {
        selectedProduct
      }
    })
    updateDashboard({ selectedEntity: selectedProduct })
  }

  const getInitialTab = (selectedProduct: IProduct) => {
    let initTab = ImpactDatasetViewType.DatabaseSearch
    const hasCustomImpact = selectedProduct.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)

    if (!selectedProduct.referenceProduct && hasCustomImpact) {
      initTab = ImpactDatasetViewType.CustomImpact
    } else if (selectedProduct.referenceProduct?.type === ProductType.ElementaryFow) {
      initTab = ImpactDatasetViewType.ElementaryFlow
    }
    return initTab
  }

  const resetFilters = () => {
    updateImpactDataset({
      currentPage: 1,
      locationQuery: '',
      databaseFilterItems: [],
      categoryFilterItems: [],
    })
  }

  const trackNavigation = (activeTab: ImpactDatasetViewType, isOnDatasetPage: boolean = false) => {
    if (!isOnDatasetPage) trackEventInSegment(TrackEventType.OPENED_DATABASE_SEARCH_WINDOW)

    const TabEventTypes: TrackEventType[] = [
      TrackEventType.VIEWED_REFERENCE_SEARCH,
      TrackEventType.VIEWED_ELEMENTARY_FLOWS_SEARCH,
      TrackEventType.VIEWED_CUSTOM_IMPACT_FORM
    ]

    const activeIndex = Object.values(ImpactDatasetViewType).findIndex(datasetViewType => datasetViewType === activeTab)
    trackEventInSegment(TabEventTypes[activeIndex])
  }

  const datasetNavigate = (
    viewType: ImpactDatasetViewType = ImpactDatasetViewType.DatabaseSearch,
    viewMode: ImpactDatasetViewMode = ImpactDatasetViewMode.Result,
    replace = true
  ) => {
    const matchPathRes = matchPath(Navigation.ImpactDataset, pathname)
    const isOnDatasetPage = isValid(matchPathRes)

    let activeTab = viewType
    if (isOnDatasetPage) {
      if (viewMode !== ImpactDatasetViewMode.Detail) {
        resetFilters()
      }
      updateImpactDataset({ collapsedDatasetHeader: false })
      setSearchParams({ activeTab, viewMode }, { replace })
    } else if (accountId && workspaceSlug && selectedProduct?.id) {
      activeTab = getInitialTab(selectedProduct)
      storeSelectedProduct(selectedProduct)
      addToBackToList?.(getBackLinkLabel())

      const impactDatasetPathname = generatePath(Navigation.ImpactDataset, {
        accountId,
        workspaceSlug,
        inventoryItemId: selectedProduct.id,
      })

      navigate({
        pathname: impactDatasetPathname,
        search: `?activeTab=${activeTab}&viewMode=result`
      })
    }

    trackNavigation(activeTab, isOnDatasetPage)
  }

  return {
    inventoryItemId,
    activeTab,
    viewMode,
    datasetNavigate,
    navigateBackToSource,
  }
}
