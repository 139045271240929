import React, { useContext, useEffect, useRef } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'primereact/tooltip'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripDots, faPlay, faTrash } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'

import { CHANGE_PHASE_TYPE, CREATE_PHASE, RENAME_PHASE } from '../../graphql/mutation'
import { IPhase } from '../../model'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { PhaseNameComponent } from '../component/phase/phase-name.component'
import { PhaseIconComponent } from '../component/phase/phase-icon.component'
import { ImpactBarComponent } from '../../product/component/impact-bar.component'
import { PhaseAddComponent } from '../component/phase/phase-add.component'
import { DeletePhaseContainer } from './delete-phase.container'

import { displayGraphqlErrors } from '../../shared/util/error'
import { isValid } from '../../shared/util/tools'
import { DecimalPointComponent } from '../../shared/component/general/decimal-point.component'
import { DecimalViewType } from '../../shared/enum'
import { getImpactBarAttr } from '../../shared/util/impact'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { normalize } from '../../../utils/tools'
import { Status } from '../../shared/enum/status'
import { NotCompatibleImpactIcon } from '../../shared/component/product/not-compatible-impact-icon'

const HeaderPhaseContainerWrapper = styled.div`
  .delete-phase-icon{
    visibility: hidden;
  }

  .drag-icon {
    top: -1.125rem;
    left: 50%;
    height: 1.5rem;
    border: 1px solid var(--gray-500);
    color: var(--gray-500);
    visibility: hidden;
  }

  .active-dragging.drag-icon {
    border: 1px solid var(--green-500);
    color: var(--green-500);
    visibility: visible;
  }

  &:hover {
    .drag-icon,
    .delete-phase-icon {
      visibility: visible;
    }
  }
`

export type HeaderPhaseContainerProps = {
  phase?: IPhase,
  options?: any,
  currentItemIsBeingDrag?: boolean
  setPanelCollapsed: (param: boolean) => void
}
export const HeaderPhaseContainer = ({
  phase,
  options = {},
  setPanelCollapsed,
  currentItemIsBeingDrag = false
}: HeaderPhaseContainerProps) => {
  const { id = '', name } = phase || {}
  const { collapsed = true } = options
  const { t } = useTranslation([ 'lifecycle', 'common' ])
  const toast = useRef<Toast>(null)
  const dragIconRef = useRef<HTMLDivElement>(null)
  const {
    enableAddingPhase,
    enabledDeletePhaseId,
    refetchLifecycle,
    lifecycleDetail,
    showDeletePhaseDialog,
    updateLifecycleDetail,
    expandedPhases = [],
    selectedPhase,
    isDraggingPhase = false,
    getMaxImpactAmount,
    lifecycleTotalImpact
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const [
    renamePhase,
    {
      error: failedRenaming,
      data: renamedPhaseData = {},
      loading: renamingPhase
    }
  ] = useMutation(RENAME_PHASE)

  const [
    changePhaseType,
    {
      error: failedChangingType,
      data: changedPhaseTypeData = {},
      loading: changingPhaseType
    }
  ] = useMutation(CHANGE_PHASE_TYPE)

  const [
    createPhase,
    {
      error: failedCreating,
      data: createdPhaseData = {},
      loading: creatingPhase
    }
  ] = useMutation(CREATE_PHASE)

  useEffect(() => {
    if (!renamingPhase && (renamedPhaseData?.renamePhase?.id || failedRenaming)) renamePhaseCallback()
  }, [ renamingPhase, renamedPhaseData, failedRenaming ])

  useEffect(() => {
    if (!changingPhaseType && (changedPhaseTypeData?.changePhaseType?.id || failedChangingType)) changePhaseTypeCallback()
  }, [ changingPhaseType, changedPhaseTypeData, failedChangingType ])

  useEffect(() => {
    if (!creatingPhase && (createdPhaseData?.createPhase?.id || failedCreating)) createPhaseCallback()
  }, [ creatingPhase, createdPhaseData, failedCreating ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const renamePhaseCallback = () => {
    try {
      if (failedRenaming) {
        throw failedRenaming
      } else if (renamedPhaseData?.renamePhase) {
        trackEventInSegment(TrackEventType.RENAMED_PHASE)
        refetchLifecycle && refetchLifecycle()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.renamePhase', { context: 'success' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.renamePhase', { context: 'error' }), error?.graphQLErrors)
    }
  }

  const changePhaseTypeCallback = () => {
    try {
      if (failedChangingType) {
        throw failedChangingType
      } else if (changedPhaseTypeData?.changePhaseType) {
        trackEventInSegment(TrackEventType.CHANGED_PHASE_TYPE)
        refetchLifecycle && refetchLifecycle()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.changePhaseType', { context: 'success' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.changePhaseType', { context: 'error' }), error?.graphQLErrors)
    }
  }

  const createPhaseCallback = () => {
    try {
      if (failedCreating) {
        throw failedCreating
      } else if (createdPhaseData?.createPhase) {
        trackEventInSegment(TrackEventType.CREATED_PHASE)
        refetchLifecycle && refetchLifecycle()
        updateLifecycleDetail({ enableAddingPhase: false })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.createPhase', { context: 'success' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.createPhase', { context: 'error' }), error?.graphQLErrors)
    }
  }

  const handleChangePhaseType = (updatedType: string) => {
    if (!updatedType || !id) return
    changePhaseType({ variables: { type: updatedType, id } })
  }

  const handleRenamePhase = (name = '') => {
    if (!name || !id) return
    renamePhase({ variables: { id, name } })
  }

  const onClick = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (collapsed) {
      updateLifecycleDetail({ expandedPhases: [ ...expandedPhases, phase ] })
    } else {
      updateLifecycleDetail({ expandedPhases: expandedPhases.filter(collapsedPhase => collapsedPhase.id !== phase?.id) })
    }

    setPanelCollapsed(!collapsed)
  }

  const handleDragClick = () => {
    if (expandedPhases.length > 0) {
      updateLifecycleDetail({ expandedPhases: [], enabledDragPhaseId: id })
    }
  }

  const handleDragMouseOver = () => {
    !currentItemIsBeingDrag && expandedPhases.length === 0 && updateLifecycleDetail({ enabledDragPhaseId: id })
  }

  const handleDragMouseOut = () => {
    updateLifecycleDetail({ enabledDragPhaseId: null })
  }

  const handleDeleteMouseOver = () => {
    updateLifecycleDetail({ enabledDeletePhaseId: id })
  }

  const handleDeleteMouseLeave = () => {
    updateLifecycleDetail({ enabledDeletePhaseId: null })
  }

  const onSubmitAddPhase = (data: any) => {
    const { name } = data

    const lifecycleID = lifecycleDetail?.id
    lifecycleID && createPhase({
      variables: {
        lifecycleID, name: name === '' ? t('form.newPhase', { context: 'placeholder' }) : name, type: 'custom'
      }
    })
  }

  const handleDeletePhase = () => {
    phase?.id && updateLifecycleDetail({ showDeletePhaseDialog: true, selectedPhase: phase })
  }

  const afterDeletePhase = () => {
    refetchLifecycle && refetchLifecycle()
    updateLifecycleDetail({ showDeletePhaseDialog: false, selectedPhase: null })
  }

  const enabledDeletingPhase = isValid(phase?.id) && enabledDeletePhaseId === phase?.id
  const impactAmount = phase?.inventory?.totalImpact?.amount
  const impactStatus = phase?.inventory?.totalImpact?.status
  const { impactType, impactPercent, impactRatioRelativeToTotal } = getImpactBarAttr({
    impactAmount: phase?.inventory?.totalImpact?.amount || '0',
    totalImpactAmount: lifecycleTotalImpact?.amount || '0',
    maxImpactAmount: getMaxImpactAmount?.(),
  })

  const phaseHasItems = phase?.inventory?.product?.hasInventory

  const getImpactBarTooltipContent = () => {
    const impactPercentage = (Math.abs(impactRatioRelativeToTotal || 0) * 100).toFixed(2)
    return t('labels.totalImpactPercentage', { value: impactPercentage, ns: 'common' })
  }

  return (
    <>
      <Toast ref={toast} position="top-right" />

      { phase && (
        <HeaderPhaseContainerWrapper className="relative flex justify-content-between">
          <div className="flex gap-1 px-2 overflow-hidden">
            { expandedPhases.length > 0
            && (
              <Tooltip
                target={dragIconRef?.current || ''}
                position="bottom"
                className="w-8rem"
                onHide={() => updateLifecycleDetail({ highlightCollapseAll: false })}
                onShow={() => updateLifecycleDetail({ highlightCollapseAll: true })}
              >
                { t('labels.collapseAll', { context: 'toDragDrop' }) }
              </Tooltip>
            )}
            {!isDraggingPhase && (
              <div
                ref={dragIconRef}
                className={classNames('absolute flex align-items-center justify-content-center border-round-md bg-white drag-icon text-lg p-1 w-2rem', {
                  'active-dragging': currentItemIsBeingDrag,
                  'cursor-pointer': expandedPhases.length > 0
                })}
                onClick={handleDragClick}
                onMouseEnter={handleDragMouseOver}
                onMouseLeave={handleDragMouseOut}
              >
                <FontAwesomeIcon icon={faGripDots} />
              </div>
            )}

            <div
              className="flex align-items-center justify-content-center w-2rem h-2rem cursor-pointer"
              data-cy={`phase-toggler-${normalize(name)}`}
              onClick={onClick}
            >
              <FontAwesomeIcon data-testid="toggle-expand" className="text-gray-300 w-2rem" icon={faPlay} rotation={!collapsed ? 90 : undefined} />
            </div>

            { name && <PhaseIconComponent phase={phase} changingPhaseType={changingPhaseType} handleChangePhaseType={handleChangePhaseType} /> }

            { name && <PhaseNameComponent phase={phase} handleRenamePhase={handleRenamePhase} /> }

            {!isDraggingPhase && (
              <div className="flex h-2rem align-items-center cursor-pointer" onMouseEnter={handleDeleteMouseOver} onMouseLeave={handleDeleteMouseLeave} onClick={handleDeletePhase}>
                <FontAwesomeIcon className={classNames('text-gray-300 delete-phase-icon', { 'text-red-500': enabledDeletingPhase })} icon={faTrash} />
              </div>
            )}
          </div>
          {phaseHasItems && (
            <div className="flex align-items-center pr-4 mr-2">
              {impactStatus === Status.NotCompatible && collapsed && (
                <NotCompatibleImpactIcon hasChild addRightSpace />
              )}
              <div className="mr-4 font-medium">
                <DecimalPointComponent
                  value={impactAmount}
                  enableTooltip
                  decimalViewType={DecimalViewType.NumericValue}
                />
              </div>
              <div
                data-testid="item-impact-bar"
                className={classNames('w-10rem pr-2', { 'opacity-0': !collapsed })}
              >
                <ImpactBarComponent
                  nodeKey={phase.id}
                  impactPercent={impactPercent}
                  impactType={impactType}
                  getTooltipContent={getImpactBarTooltipContent}
                />
              </div>
            </div>
          )}
        </HeaderPhaseContainerWrapper>
      )}

      { enableAddingPhase && !phase && <PhaseAddComponent handleAddPhase={onSubmitAddPhase} /> }

      { showDeletePhaseDialog && selectedPhase?.id === phase?.id && <DeletePhaseContainer afterDeletePhase={afterDeletePhase} />}
    </>
  )
}
