import { call, delay, put, select, takeLatest, cancelled } from 'redux-saga/effects'

import { SAGA } from '../../utils/const'
import resource from '../../utils/resource/resource'
import { setIsLoadingReferenceProductsAction } from '../actions/loading.actions'

import { setReferenceProductResultsAction, setReferenceDatabasesAction } from '../actions/referenceProducts.actions'
import ReferenceProductsSelector from '../selectors/referenceProducts.selector'

import { dispatchErrors } from './helpers'
import { userAuth0Var } from '../../graphql/cache'
import { selectedWorkspaceVar } from '../../graphql/cache'

export const SEARCH_REFERENCE_PRODUCTS = 'SEARCH_REFERENCE_PRODUCTS_SAGA'
export const GET_REFERENCE_DATABASES = 'GET_REFERENCE_DATABASES_SAGA'
export const STORE_SEARCH_TERM = 'STORE_SEARCH_TERM_SAGA'

export const searchReferenceProductsSaga = ({ query, fuzzy, locationQuery, categoryId, types, currentPage, pageSize, databases, impactMethodId }) => ({
  type: SEARCH_REFERENCE_PRODUCTS,
  query, fuzzy, locationQuery, categoryId, types, currentPage, pageSize, databases, impactMethodId
})

export const getReferenceDatabasesSaga = query => ({
  type: GET_REFERENCE_DATABASES,
  query
})

export const storeSearchTermSaga = query => ({
  type: STORE_SEARCH_TERM,
  query
})

/**
 * @param {{query: string, fuzzy:string, locationQuery: string, categoryId: string, types: [], currentPage: int, pageSize: int}} action
 */
function* doSearchReferenceProducts(action) {
  yield delay(SAGA.DEBOUNCE_MS)
  yield put(setIsLoadingReferenceProductsAction(true))
  const pageSize = yield select(ReferenceProductsSelector.referencePageSize)
  let searchResults = null
  try {
    const referenceProductsArgs = {
      query: action.query || '',
      fuzzyType: action.fuzzy || 'EXACT',
      locationQuery: action.locationQuery || '',
      categoryId: action.categoryId || '',
      types: action.types || [],
      databases: action.databases || [],
      currentPage: action.currentPage || 1,
      pageSize,
      impactMethodId: action.impactMethodId
    }
    searchResults = yield call(resource.cancellableQueryByParams, 'referenceProducts', referenceProductsArgs)
    yield put(setReferenceProductResultsAction({
      items: searchResults.items,
      total: searchResults.total
    }))
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingReferenceProductsAction(false))
    if (yield cancelled()) {
      // TODO MOB-3533 find a way to call cancel function of abortable graphql queries
      // searchResults && searchResults.cancel()
    }
  }
}

function* doGetReferenceDatabasesSaga() {
  try {
    const { space: selectedSpace } = selectedWorkspaceVar() || {}
    const methodId = selectedSpace?.impactMethod?.id
    if(!methodId) throw new Error("Impact method is not ready")

    const referenceDatabasesArgs = { methodId }
    const referenceDatabases = yield call(resource.queryByParams, 'referenceDatabases', referenceDatabasesArgs)
    yield put(setReferenceDatabasesAction(referenceDatabases))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* doStoreSearchTermSaga(action) {
  try {
    const { userId: userID } = userAuth0Var() || {}
    yield call(resource.mutateByParams, 'storeSearchTerm', { query: action.query, userID })
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export default function* productReferenceSaga() {
  yield takeLatest(SEARCH_REFERENCE_PRODUCTS, doSearchReferenceProducts)
  yield takeLatest(GET_REFERENCE_DATABASES, doGetReferenceDatabasesSaga)
  yield takeLatest(STORE_SEARCH_TERM, doStoreSearchTermSaga)
}
