import {
  faArchway,
  faBlockBrick,
  faBowlSoftServe,
  faCandyBar,
  faCanFood,
  faClothesHanger,
  faLampStreet,
  faSack,
  faShoePrints,
  faSocks,
  faVest,
  faWandMagicSparkles,
  faWashingMachine,
  faWineBottle,
  faNoteSticky,
} from '@fortawesome/pro-solid-svg-icons'
import { ProductTemplate } from '../../../__generated__/graphql'
import { IProductTemplate } from '../interface/product-template'

export const PRODUCT_TEMPLATES: IProductTemplate[] = [
  {
    key: ProductTemplate.Brick1PalletWith1000PcsV38,
    iconName: faBlockBrick,
    name: 'brick',
    industry: 'constructionMaterials',
    isPopular: true
  },
  {
    key: ProductTemplate.BoxerShortsV38,
    iconName: faClothesHanger,
    name: 'boxerShorts',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.ChocolateBarMilkPeanutsV38,
    iconName: faCandyBar,
    name: 'chocolateBar',
    industry: 'foodBeverage',
    isPopular: true
  },
  {
    key: ProductTemplate.StreetLightV38,
    iconName: faLampStreet,
    name: 'streetLight',
    industry: 'electronics',
    isPopular: true
  },
  {
    key: ProductTemplate.AluminiumCan330mlV38,
    iconName: faCanFood,
    name: 'aluminiumCan',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.GlassBottle330mlV38,
    iconName: faWineBottle,
    name: 'glassBottle',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.RainJacketV38,
    iconName: faVest,
    name: 'rainJacket',
    industry: 'apparelTextile',
    isPopular: true
  },
  {
    key: ProductTemplate.Concrete1m3V36,
    iconName: faArchway,
    name: 'concrete',
    industry: 'constructionMaterials',
    isPopular: false
  },
  {
    key: ProductTemplate.JeansV38,
    iconName: faWashingMachine,
    name: 'jeans',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.PlasticBagsV38,
    iconName: faSack,
    name: 'plasticBags',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.SocksV38,
    iconName: faSocks,
    name: 'socks',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.Yoghurt1lV38,
    iconName: faBowlSoftServe,
    name: 'yoghurt',
    industry: 'foodBeverage',
    isPopular: false
  },
  {
    key: ProductTemplate.SneakersV38,
    iconName: faShoePrints,
    name: 'sneakers',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.ProductStructure,
    iconName: faWandMagicSparkles,
    name: 'productStructure',
    industry: 'general',
    isPopular: true
  },
  {
    key: ProductTemplate.StickyNote,
    iconName: faNoteSticky,
    name: 'stickyNote',
    industry: 'general',
    isPopular: true
  }
]
