import { AnalyticsBrowser } from '@segment/analytics-next' // SEGMENT must be set to version 2.0 in dashboard settings
import { isEmpty } from './tools'
import { DEBUG } from '../utils/const'
import { selectedAccountVar, userAuth0Var, userVar } from '../graphql/cache'

const { SEGMENT_KEY = 'no-env' } = window.env || {}

const getMappedUserData = () => {
  const userAPI = userVar()
  const userAuth0 = userAuth0Var()
  const user = { ...userAPI, ...userAuth0 }

  if (!user) return null

  const userMetadata = user.metadata || {}
  const userData = {
    userId: user.id,
    first_name: userMetadata.firstName,
    last_name: userMetadata.lastName,
    email: user.email,
    created_date: user.createdDate,
    contact_type: userMetadata.companyType,
    job_function: userMetadata.jobFunction,
    country: userMetadata.country,
    phone: userMetadata.phone,
    companyName: userMetadata.name,
    signupCompleted: userMetadata.signupCompleted,
    sign_up_type: userMetadata.sign_up_type,
  }

  const [ userFirstAccount ] = user.accounts
  const { account: selectedAccount = null } = selectedAccountVar() || {}
  const currentAccount = isEmpty(selectedAccount) ? userFirstAccount : selectedAccount

  const { subscription } = currentAccount || {}
  const { startAt, cancelAt, canceledAt } = subscription || {}

  // currentAccount is empty on first login
  const accountData = isEmpty(currentAccount) ? {}
    : {
      current_account: currentAccount.name,
      plan_type: currentAccount.subscriptionStatus,
      plan_tier: currentAccount.subscriptionId,
      is_trialing: currentAccount.isTrialing,
      trial_start: currentAccount.createdAt,
      trial_end: currentAccount.trialEnd,
      subscription_start_at: startAt,
      subscription_cancel_at: cancelAt,
      subscription_canceled_at: canceledAt,
      subscription_plan_name: currentAccount?.subscription?.plan?.name
    }

  return { ...userData, ...accountData,  }
}

const defaultOptions = { integrations: { 'Google Analytics 4': false, 'Google Tag Manager': false } }

const initializeLibrary = () => new Promise((resolve, reject) => {
  if (SEGMENT_KEY === 'DO_NOT_TRACK') reject('initializeLibrary: segment tracking disabled in env.js')

  if (window.analytics) {
    return resolve(window.analytics)
  }
  AnalyticsBrowser.load({ writeKey: SEGMENT_KEY })
    .then(response => {
      const [ analytics ] = response
      window.analytics = analytics
      resolve(window.analytics)
    }).catch(e => console.error(e))
})

const identifyEvent = () => new Promise((resolve, reject) => {
  initializeLibrary()
    .then(analytics => {
      if (!analytics) reject('identifyEvent: segment library not found')
      if (DEBUG.SEGMENT) analytics.debug()

      const userMappedData = getMappedUserData()
      if (!userMappedData) reject('identifyEvent: user not found')

      analytics.identify(
        userMappedData.userId,
        userMappedData,
        defaultOptions,
        () => resolve(analytics)
      )
    }).catch(e => console.error(e))
})

/**
 * @param {string} eventName
 * @param {{}} eventProperties
 */
export const trackEventInSegment = (eventName, eventProperties={}, eventOptions={}) => {
  identifyEvent()
    .then(analytics => {
      if (analytics) analytics.track(eventName, eventProperties, { ...eventOptions, ...defaultOptions })
    })
}

/**
 * @param {string} eventCategory
 * @param {string} eventName
 */
export const trackPageInSegment = (eventCategory, eventName='', eventProperties={}, eventOptions={}) => {
  identifyEvent()
    .then(analytics => {
      if (analytics) analytics.page(eventCategory, eventName, eventProperties, { ...eventOptions, ...defaultOptions })
    })
}
